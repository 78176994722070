<template>
  <header>
    <!-- tp-header-area-start -->
    <div
      id="header-sticky"
      class="
        tp-header-area
        header-pl-pr header-transparent header-border-bottom
      "
    >
      <div class="container-fluid">
        <div class="row g-0 align-items-center">
          <div class="col-xl-2 col-lg-2 col-md-4 col-6">
            <div class="tp-logo tp-logo-border">
              <router-link to="/">
              <a>
                <img src="assets/img/logo/logo.png" alt="site web application web opentek" />
              </a>
            </router-link>
            </div>
          </div>
          <div
            class="
              col-xl-10 col-lg-10 col-md-8 col-6
              d-flex
              justify-content-end
            "
          >
            <div class="tp-main-menu d-none d-xl-block">
              <nav id="mobile-menu" ref="mobileMenu">
                <ul >
                  <li><router-link to="/">Accueil</router-link></li>
                  <li><router-link to="/Apropos">Apropos</router-link></li>
                  <li><router-link to="/Nos-services">Nos-Services</router-link></li>
                  <li><router-link to="/Commandez">Faites-la-commande</router-link></li>
                  <a href="tel:0025377604069" style="font-size: 16px;">
    <i class="fa fa-phone"></i> <span>0025377604069</span> 
  </a>

             
                </ul>
              </nav>
            </div>
            <div class="tp-header-right">
              <ul>
                <li class="d-none d-md-inline-block search-wrapper">
              
                </li>
               
                <li>
                  <button class="tp-menu-bar" aria-label="Menu" @click="openOffCanvas" 
                    ><i class="fas fa-bars"></i
                  ></button>
                </li>
              </ul>
            </div>


          </div>
        </div>
      </div>
    </div>
    <!-- tp-header-area-end -->
  </header>

  <div class="tp-offcanvas-area">
    <div class="tpoffcanvas" :class="{ opened: isOffCanvasOpen }">
      <div class="tpoffcanvas__close-btn" :class="{ apply: isOffCanvasOpen }" @click="closeOffCanvas">
        <button class="close-btn"><i class="fal fa-times"></i></button>
      </div>
      <div class="tpoffcanvas__logo pt-50">
        <router-link  to="/"> <a>
          <img src="assets/img/logo/declinaison.png" alt="djibouti numerique" />
        </a>
      </router-link>
      </div>
      <div class="tpoffcanvas__text">
        <p>
          Agence digitale de developpement des applications informatique et du Designe graphique.
        </p>
      </div>


      <!-- <div class="mobile-menu" > -->
        <div>
      </div>
      <div class="tpoffcanvas__info">
        <h3 class="offcanva-title">Contactez-nous</h3>
        <div class="tp-info-wrapper mb-20 d-flex align-items-center">
          <div class="tpoffcanvas__info-icon">
            <a href="#"><i class="fal fa-envelope"></i></a>
          </div>
          <div class="tpoffcanvas__info-address">
            <span>Email</span>
            <a href="opentek.dj@gmail.com
">opentek.dj@gmail.com
</a>
          </div>
        </div>
        <div class="tp-info-wrapper mb-20 d-flex align-items-center">
          <div class="tpoffcanvas__info-icon">
  <a href="tel:0025377604069"><i class="fal fa-phone-alt"></i></a>
</div>
<div class="tpoffcanvas__info-address">
  <span>TéléPhone</span>
  <a href="tel:0025377604069">(+253) 77604069</a>
</div>

        </div>
        <div class="tp-info-wrapper mb-20 d-flex align-items-center">
          <div class="tpoffcanvas__info-icon">
            <a href="#"><i class="fas fa-map-marker-alt"></i></a>
          </div>
          <div class="tpoffcanvas__info-address">
            <span>Addresse</span>
            <span>
             
              Centre ville, Djibouti
            </span>
          </div>
        </div>
      </div>
      <div class="tpoffcanvas__social text-center">
        <div class="social-icon m-2">
          <a href="#"><i class="fab fa-twitter"></i></a>
          <a href="#"><i class="fab fa-instagram"/></a>
          <a href="#"><i class="fab fa-facebook-square "></i></a>
        </div>
      </div>
    </div>
  </div>

  <div class="body-overlay" ></div>
</template>
<script>
export default {
  name: "Header",
    data() {
    return {
      isOffCanvasOpen: false,
    };
  },
   mounted() {
    $(this.$refs.mobileMenu).meanmenu({
      meanMenuContainer: '.mobile-menu',
      meanScreenWidth: "1199",
      meanExpand: ['<i class="fal fa-plus"></i>'],
    });
  },
  methods: {
    openOffCanvas() {
      this.isOffCanvasOpen = true;
    },
    closeOffCanvas() {
      this.isOffCanvasOpen = false;
    },
  },
};
</script>
<style>



</style>