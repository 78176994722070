import { createRouter, createWebHashHistory } from 'vue-router'
import accueil from '../views/Accueil.vue'

const routes = [
  
  {
    path: '/',
    name: 'Accueil',
    component: accueil
  },
  {
    path: '/Apropos',
    name: 'Apropos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Apropos.vue')
  },
  {
    path: '/Nos-services',
    name: 'Nos-services',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Services')
  },
  {
    path: '/Commandez',
    name: 'Commandez',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Commande')
  },

]
const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  history: createWebHashHistory(), // changer la ligne ici
  routes
})

export default router
