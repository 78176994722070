<template>
   <footer>
    <!-- tp-footer-area-start -->
    <div class="tp-footer-area pb-30 bg-bg  p-relative">
      <div class="container">
        <div class="footer-border-top pt-100">
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-6 mb-30">
              <div class="tp-footer-widget z-index-3">
                <div class="tp-footer-widget__title">
                  <h4 class="tp-footer-title  text-decoration-underline">Contact info</h4>
                </div>
                <div class="tp-footer-widget__list">
                  <ul>
                    <li style="color:white"><i class="fa fa-mobile" aria-hidden="true"></i>
77604069</li>
                    <li style="color:white" ><i class="fa fa-envelope" aria-hidden="true"></i>
opentek.dj@gmail.com</li>

                                        <li style="color:white" ><i class="fa fa-user" aria-hidden="true"></i>

                                          <a href="https://twitter.com/opentekdj" target="_blank" >
@opentekdj</a></li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-6 mb-30">
              <div class="tp-footer-widget z-index-3">
                <div class="tp-footer-widget__title">
                    <h4 class="tp-footer-title  text-decoration-underline">Nos expertises</h4>
                </div>
                <div class="tp-footer-widget__list">
                  <ul>
  <li style="color:white; list-style-type: disc;">Développement des applications</li>
  <li style="color:white; list-style-type: disc;">Transformation Digitale</li>
  <li style="color:white; list-style-type: disc;">Design Graphique</li>
  <li style="color:white; list-style-type: disc;">ICT4D</li>

</ul>

                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-2 col-md-3 col-6 mb-30">
                     <div class="tp-footer-widget footer-space-two z-index-3">
                        <div class="tp-footer-widget__title">
                        </div>
                        <div class="tp-footer-widget__list">
                          <img
  class="mascot-animation"
  src="assets/img/mascot.webp"
  alt="opentek agence digitale a djibouti"
  aria-label="opentek"
/>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-3 col-lg-2 col-md-3 col-6 mb-30">
  <div class="tp-footer-widget z-index-3">
    <div class="tp-footer-widget__social mb-20 ">
      <a href="#" style="pointer-events: none;">
        <div>
          <img
            src="assets/img/slider/ai.webp"
            alt="opentek agence digitale a djibouti"
            aria-label="opentek "
            class="img-fluid w-100" 
            style="max-height: 200px; max-width: 100%;"
          />
        </div>
        <div class="footer-icons z-index-9"></div>
      </a>
    </div>
  </div>
</div>

          </div>
        </div>
        <div class="copy-right-wrapper footer-border-top pt-40 z-index-3">
          <div class="row">
            <div class="col-xl-6 col-lg-7 col-12">
              <div class="copyright-left text-center text-lg-start">
                <p>
                  ©Copy RIght @{{ currentYear }} Tous droits Reservés - Opentek-djibouti- ville
                </p>
              </div>
            </div>
            <div class="col-xl-6 col-lg-5 col-12">
              <div class="copyright-right-side text-center text-lg-end">
                <ul>
                  <li><router-link to="/Apropos"><a href="#">Opentek SARL</a></router-link></li>
                  <li><a href="#"  v-on:click.prevent>Conditions</a></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- tp-footer-area-end -->
      <!-- tp-footer-area-end -->

      <button class="scroll-top scroll-to-target"  aria-label="Scroll to Top" @click="scrollTop" data-target="html">
         <i class="far fa-angle-double-up"></i>
     </button>
  </footer>
</template>
<script>
export default {
    name:'Footer ',
    data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
    methods: {
    scrollTop() {
      const target = document.querySelector(".scroll-to-target");
      const id = target.getAttribute("data-target");
      const elem = document.querySelector(id);
      elem.scrollIntoView({ behavior: "smooth" });
    },
  },
}
</script>
<style>
    .mascot-animation {
  animation: bounce 1s infinite alternate;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}

</style>